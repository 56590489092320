@import "./scssStyles/variables";
@import "./scssStyles/global";
@import "./scssStyles/_form";
@import "./scssStyles/sections/_complete";
@import "./scssStyles/sections/forcast";
@import "./scssStyles/sections/header";
@import "./scssStyles/button";
@import "./scssStyles/sections/_packages";

.orange {
    color: #f28c28
}

.orange-btn {
    background: #f28c28;
    display: block;
    padding: 0.4rem;
    border-radius: 0.4rem;
    text-align: center;
    font-weight: bold;
    margin-top: 0.4rem;
    color: white;
}